// import _get from "lodash/get";
import * as TYPES from "./carrierActionTypes";
const defaultState = {
  count: 0,
  carriers: []
};

export default function carrier(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CARRIERS:
      return {
        ...state,
        carriers: action.value
      };
    case TYPES.CARRIER_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.CARRIER_SEL:
      return {
        ...state,
        selectedCarrier: action.value
      };
    default:
      return state;
  }
}
