import { combineReducers } from "redux";
import auth from "./auth/authReducers";
import driver from "./driver/driverReducers";
import carrier from "./carrier/carrierReducers";
import business from "./business/businessReducers";
import doc from "./doc/docReducers";
import recruiting from "./recruiting/recruitingReducers";
import equipment from "./equipment/equipmentReducers";
import app from "./app/appReducer";
import documentData from "./document/documentReducer";
import eldbooks from "./hos/eldbooksReducers";
import keepTrucking from "./hos/keepTruckingReducers";
import samsara from "./hos/smReducers";
import expense from "./expense/expenseReducers";
import vendor from "./vendor/vendorReducers";
import contact from "./contact/contactReducers";
import emailer from "./emailer/emailerReducers";

export default combineReducers({
  appData: app,
  authData: auth,
  docData: doc,
  driverData: driver,
  carrierData: carrier,
  businessData: business,
  recruitingData: recruiting,
  equipmentData: equipment,
  eldbooksData: eldbooks,
  keepTruckingData: keepTrucking,
  smData: samsara,
  expenseData: expense,
  vendorData: vendor,
  contactData: contact,
  emailData: emailer,
  documentData
});
