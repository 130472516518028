// import _get from "lodash/get";
import * as TYPES from "./businessActionTypes";
const defaultState = {
  BUSINESS_ALIAS: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.BUSINESS_ALIAS:
      return {
        ...state,
        businessAlias: action.value
      };
    case TYPES.BUSINESS_FACTORINGS:
      return {
        ...state,
        factorings: action.value
      };
    default:
      return state;
  }
}
