// import _get from "lodash/get";
import * as TYPES from "./equipmentActionTypes";

const defaultState = {
  tractors: [],
  tractorCount: 0,
  tractorSelected: {},
  trailers: [],
  trailerCount: 0,
  tractorLocations: [],
  trailerSelected: {}
};

export default function equipment(state = defaultState, action) {
  switch (action.type) {
    case TYPES.TRACTORS:
      return {
        ...state,
        tractors: action.value
      };
    case TYPES.TRACTOR_COUNT:
      return {
        ...state,
        tractorCount: action.value
      };
    case TYPES.SELECTED_TRACTOR:
      return {
        ...state,
        tractorSelected: action.value
      };
    case TYPES.TRAILERS:
      return {
        ...state,
        trailers: action.value
      };
    case TYPES.TRAILER_COUNT:
      return {
        ...state,
        trailerCount: action.value
      };
    case TYPES.TRACTOR_LOCATIONS:
      return {
        ...state,
        tractorLocations: action.value
      };
    case TYPES.SELECTED_TRAILER:
      return {
        ...state,
        trailerSelected: action.value
      };
    default:
      return state;
  }
}
