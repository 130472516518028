import * as TYPES from "./recruitingActionTypes";
const defaultState = {
  count: 0,
  recruitings: [],
  selectedRecruiting: {}
};

export default function recruiting(state = defaultState, action) {
  switch (action.type) {
    case TYPES.RECRUITING_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.SEL_RECRUITING:
      return {
        ...state,
        selectedRecruiting: action.value
      };
    case TYPES.ALL_RECRUITING:
      return {
        ...state,
        recruitings: action.value
      };
    case TYPES.CARRIER_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.SEL_CARRIER:
      return {
        ...state,
        selectedCarrier: action.value
      };
    case TYPES.ALL_CARRIER:
      return {
        ...state,
        carriers: action.value
      };
    default:
      return state;
  }
}
